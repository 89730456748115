export const SliderData = [
  {
    image: "./assets/chq/2.png",
  },
  {
    image: "./assets/chq/1.png",
  },
  {
    image: "./assets/chq/3.png",
  },
  {
    image: "./assets/chq/4.png",
  },
  {
    image: "./assets/chq/5.png",
  },
];
